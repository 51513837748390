import React, { memo, useState } from "react";
import styles from './WebApps.module.scss';
import activeArrow from '../../../resources/icons/arractive.svg';
import ipad1 from '../../../resources/images/iPad1.png';
import ipad2 from '../../../resources/images/iPad2.png';
import ipad3 from '../../../resources/images/iPad3.png';
import ipad4 from '../../../resources/images/iPad4.png';
import { Swiper, SwiperSlide } from "swiper/react";

const WebApps = () => {
    const [swiper, setSwiper] = useState(null);

    const nextSlide = () => {
        swiper.slideNext();
    }

    const prevSlide = () => {
        swiper.slidePrev();
    }

    return (
        <div className={styles.container}>
            <div className={styles.WebMain}>
                <div className={styles.WebTitleBlock}>
                    <div className={styles.WebTitleText}>
                    Веб-приложения
                    </div>
                    <div className={styles.WebArrowBlock}>
                        <div onClick={prevSlide}>
                            <img src={activeArrow} alt="Arrow" className={styles.prevArrow} />
                        </div>
                        <div onClick={nextSlide}>
                            <img src={activeArrow} alt="Arrow" />
                        </div>
                    </div>
                </div>
                <div className={styles.WebSwiperWrapper}>
                    <Swiper spaceBetween={50}
                            slidesPerView={1}
                            loopedSlides={true}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => setSwiper(swiper)}
                            className={styles.WebSwiper}>
                        <SwiperSlide className={styles.WebSlide}>
                            <img src={ipad1} alt="ipad1" className={styles.webIpadImg} />
                            <div className={styles.WebSlideText}>
                            Сайт-каталог предметом ручной работы
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.WebSlide}>
                            <img src={ipad2} alt="ipad2" className={styles.webIpadImg} />
                            <div className={styles.WebSlideText}>
                            Официальный сайт Иркутского завода дорожных машин
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.WebSlide}>
                            <img src={ipad3} alt="ipad3" className={styles.webIpadImg} />
                            <div className={styles.WebSlideText}>
                            Магазин одежды для активного отдыха
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.WebSlide}>
                            <img src={ipad4} alt="ipad4" className={styles.webIpadImg} />
                            <div className={styles.WebSlideText}>
                            Услуги по строительству дорог и оказанию услуг спецтехникой
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default memo(WebApps);