import React, { useState } from "react";
import styles from './MobileApps.module.scss';
import iphone1 from '../../../resources/images/iphone1.png';
import iphone2 from '../../../resources/images/iphone2.png';
// import iphone3 from '../../../resources/images/iphone3.png';
import iphone1M from '../../../resources/images/netmanM.png';
import iphone2M from '../../../resources/images/ssM.png';
// import iphone3M from '../../../resources/images/fmM.png';

const MobileApps = () => {
    const smartSharingDesc = 'Smart Sharing - приложение позволяющее полностью, удалённо управлять' +
                                'сдачей объектов под аренду. В приложение интегрированы' +
                                'сервисы уборки помещений, ремонта а также надёжная система охраны. Приложение удобно для поиска аренды на разный период.';

    const netmanDesc = 'Netman - Игра в стиле квест. Игра позволяет организоваться в команды, и в своей местности выполнять задания. Раздел с новеллами содержит своего рода разные предыстории. Герои среди нас.';

    // const fifthDimension = 'Пятое измерение - приложение ј кјрјами по јаморазвитию от одного автора. Приложение јојтоит из кјрјов ј ајдиозапијями. Вјтроена оплата картой.  При добавлении новых ајдиозапијей пользователям приходит јведомления.';

    const [iphoneImage, setIphoneImage] = useState(iphone1);
    const [iphoneMImage, setIphoneMImage] = useState(iphone2M);
    const [gamesClass, setGamesClass] = useState(styles.mobileBtn);
    const [servicesClass, setServicesClass] = useState(styles.mobileBtnActive);
    const [descriptionText, setDescriptionText] = useState(smartSharingDesc);

    const setServicesValue = () => {
        setIphoneImage(iphone1);
        setIphoneMImage(iphone2M);
        setServicesClass(styles.mobileBtnActive);
        setGamesClass(styles.mobileBtn);
        setDescriptionText(smartSharingDesc)
    };

    const setGamesValue = () => {
        setIphoneImage(iphone2);
        setIphoneMImage(iphone1M);
        setGamesClass(styles.mobileBtnActive);
        setServicesClass(styles.mobileBtn);
        setDescriptionText(netmanDesc)
    }

    return (
        <div className={styles.container}>
            <div className={styles.MobileMain}>
                <div className={styles.mobileTitleBlock}>
                    <div className={styles.mobileTitleTextBlock}>
                        <div className={styles.mobileTitleText}>
                        Мобильные приложенния
                        </div>
                        <div className={styles.mobileTitleDesc}>
                        Разработка мобильных приложений с использованием дополненной и виртуальной реальности.
                        </div>
                    </div>
                    <div className={styles.mobileBtnsBottomBlock}>
                        <div className={gamesClass} onClick={setGamesValue}>
                        Игры
                        </div>
                        <div className={servicesClass} onClick={setServicesValue}>
                        Сервисы
                        </div>
                    </div>
                </div>
                <div className={styles.mobileIphoneImgBlock}>
                    <img src={iphoneImage} alt="PHONE" className={styles.mobileIphoneImg} />
                </div>

                <div className={styles.mobileIphoneImgBlockMActive}>
                    <img src={iphoneMImage} alt="PHONE" className={styles.mobileIphoneImg} />
                </div>
                <div className={styles.mobileDescBlock}>
                    <div className={styles.mobileDescText}>
                        {descriptionText}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MobileApps;