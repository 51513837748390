import React, { memo } from "react";
import styles from '../../containers/Content/BusinesSystems/BusinesSystems.module.scss';

const BusinesBlockDescription = (props) => {
    return (
        <div>
            <div className={styles.businesDescriptionTitle}>
                {props.titleText}
            </div>
            <div className={styles.businesDescriptionDesc}>
                <p>
                    {props.descText}
                </p>
                <ul>
                    <li className={styles.businesDescriptionLi}>
                        <p>{props.firstElText}</p>
                    </li>
                    <li className={styles.businesDescriptionLi}>
                        <p>{props.secondElText}</p>
                    </li>
                    <li className={styles.businesDescriptionLi}>
                        <p>{props.thirdElText}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default memo(BusinesBlockDescription);