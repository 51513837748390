import { memo } from "react";
import styles from './FillAppButton.module.scss'
import ReactModal from "react-modal";

const FillAppButton = (props) => {
    return (
        <button {...props} className={styles.FillAppBtn}>
            Заполнить заявку
        </button>
    )
};

export default memo(FillAppButton);