import React, { memo } from "react";
import styles from './GameDev.module.scss';
import gameBackImg from '../../../resources/images/gamedevback2.png';
import gamepadGameDev from '../../../resources/images/gamepad.png';
import unrlngn from '../../../resources/icons/unrealengin.png';
import unity from '../../../resources/icons/unity.png';
import gameDevMainM from '../../../resources/images/gameDevMainBlockM.png';
import gameDevStackBlockM from '../../../resources/images/gameDevStackBlockM.png';

const GameDev = () => {
    return (
        <div className={styles.container}>
            <div className={styles.GameMain}>
                <img src={gameBackImg} alt="back" className={styles.gameBackImg} />
                <div className={styles.GameDevBlock}>
                    <div className={styles.GameDevStackBlock}>
                        {/* <img src={unrlngn} alt="nrlgn" />
                        <img src={unity} alt="unity" /> */}
                    </div>
                    <div className={styles.GameDevMainBlock}>
                        <div className={styles.GameDevTitleText}>
                        GAME
                        <br />
                        DEV
                        </div>
                        <img src={gamepadGameDev} alt="gamepad" className={styles.gameDevGamepad} />
                    </div>
                </div>
            </div>
            <div className={styles.GameMainM}>
                <img src={gameDevStackBlockM} alt="stack" className={styles.gameMainImgM} />
                <img src={gameDevMainM} alt="main" className={styles.gameStackImgM} />
            </div>
        </div>
    )
}

export default memo(GameDev);