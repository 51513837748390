import React, { memo } from "react";
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.FooterMain}>
                <div className={styles.FooterTitle}>
                Solar Snow Light
                </div>
                <div className={styles.FooterMainBlock}>
                    <div>
                        <div className={styles.FooterContentElTitle}>
                        Адрес
                        </div>
                        <a href="https://2gis.ru/irkutsk/geo/1548748027072939?m=104.266264%2C52.25692%2F16" className={styles.FooterContentElDesc}>
                        улица Академика Курчатова, 13 <br />
                        Иркутск
                        </a>
                    </div>
                    <div>
                        <div className={styles.FooterContentElTitle}>
                        Контакты
                        </div>
                        <a href="#" className={styles.FooterContentElDesc}>
                        office@solarsnowlight.ru
                        </a>
                        <a href="#" className={styles.FooterContentElDesc}>
                        INSTAGRAMM
                        </a>
                        <a href="#" className={styles.FooterContentElDesc}>
                        VK
                        </a>
                    </div>
                    <div>
                        <div className={styles.FooterContentElTitle}>
                        Навигация
                        </div>
                        <a href="#" className={styles.FooterContentElDesc}>
                        О компании
                        </a>
                        <a href="#" className={styles.FooterContentElDesc}>
                        Веб-сайты
                        </a>
                        <a href="#" className={styles.FooterContentElDesc}>
                        Мобильные приложения
                        </a>
                        <a href="#" className={styles.FooterContentElDesc}>
                        3Д моделирование
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Footer);