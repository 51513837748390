import { React, memo, useState } from "react";
import styles from './StarterBlock.module.scss';
import logo from '../../../resources/images/headLogo.svg';
import FillAppButton from "../../../UI/FillAppButton/FillAppButton";
import backVideo from '../../../resources/videos/giphy.gif.mp4';
import Modal from "../../../components/ModalWindow/ModalWindow";

const StarterBlock = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.videoWrapper}>
                <video src={backVideo} className={styles.backgroundVideo} autoPlay loop muted/>
            </div>
            
            <div className={styles.starterMain}>
                <div className={styles.headLogoWrapper}>
                    <img src={logo} alt="logo" className={styles.headLogo} />
                </div>
                <div className={styles.starterMainBlock}>
                    <div className={styles.mainWrapper}>
                        <h1 className={styles.mainTitleText}>
                            Solar Snow Light
                        </h1>
                        <p className={styles.mainDescText}>
                            Компания, решающая проблемы цифровизации Вашего бизнеса
                        </p>
                    </div>
                </div>
                <div className={styles.lowerWrapper}>
                    <div className={styles.trippleFr}>
                        <p className={styles.scrollText}></p>
                    </div>
                    <div className={styles.tripplePr}>
                        <FillAppButton onClick={() => setIsOpen(true)} />
                        {isOpen && <Modal setIsOpen={setIsOpen} />}
                    </div>
                    <div className={styles.trippleFr}>
                        
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(StarterBlock);
