import React, { memo, useState } from "react";
import styles from './ItSchool.module.scss';
import laptop from '../../../resources/images/openedLaptop.png';
import FillAppButton from "../../../UI/FillAppButton/FillAppButton";
import astronaut from '../../../resources/images/astronaut.png';
import Modal from "../../../components/ModalWindow/ModalWindow";

const ItSchool = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.GameMain}>
                <div className={styles.ItSTitleBlock}>
                IT школа "Взлет"
                </div>
                <div className={styles.ItSMainBlock}>
                    <div className={styles.ItSContentBlock}>
                        <img src={laptop} alt="laptop" className={styles.ItSLaptop} />
                        <div className={styles.ItSDescBlock}>
                            <p className={styles.ItSSescsText}>Программирование</p>
                            <p className={styles.ItSSescsText}>Дизайн</p>
                            <p className={styles.ItSSescsText}>Профессиональный английский</p>
                            <p className={styles.ItSSescsText}>Базовое владение ПК</p>

                            <div className={styles.FillBtnWrapper}>
                                <FillAppButton onClick={() => setIsOpen(true)} />
                                {isOpen && <Modal setIsOpen={setIsOpen} />}
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={astronaut} alt="astronaut" className={styles.astronautImg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ItSchool);