import React, { memo } from "react";
import styles from './CasesBlock.module.scss';
import codeIco from '../../../resources/icons/code.svg';
import fileIco from '../../../resources/icons/file.svg';
import penIco from '../../../resources/icons/pen.svg';
import toolIco from '../../../resources/icons/tool.svg';

const CasesBlock = () => {
    return (
        <div className={styles.container}>
            <div className={styles.casesMain}>
                <div className={styles.casesBlock}>
                    <div className={styles.casesLeftBlock}>
                        <div className={styles.caseBlock}>
                            <div className={styles.caseTitle}>
                                <div className={styles.caseIco}>
                                    <img src={codeIco} alt="code" />
                                </div>
                                <p className={styles.caseTitleText}>
                                    Рефакторинг кода
                                </p>
                            </div>
                            <p className={styles.caseDescText}>
                                Улучшение исходного кода вашей программы, чтобы он стал более простым и понятным.
                            </p>
                        </div>
                        
                        <div className={styles.caseBlock}>
                            <div className={styles.caseTitle}>
                                <div className={styles.caseIco}>
                                    <img src={penIco} alt="pen" />
                                </div>
                                <p className={styles.caseTitleText}>
                                    Обновление дизайна
                                </p>
                            </div>
                            <p className={styles.caseDescText}>
                                Оптимизация навигации, улучшения внешнего вида вашего продукта. Мы создаем стиль, который передает всю идею продукта и раскрывает все его преимущества. 
                            </p>
                        </div>
                    </div>
                    <div className={styles.casesRightBlock}>
                    <div className={styles.caseBlock}>
                            <div className={styles.caseTitle}>
                                <div className={styles.caseIco}>
                                    <img src={toolIco} alt="tool" />
                                </div>
                                <p className={styles.caseTitleText}>
                                    Teхническая поддержка
                                </p>
                            </div>
                            <p className={styles.caseDescText}>
                                Решения проблем и поддержка вашего програмного обеспечения
                            </p>
                        </div>

                        <div className={styles.caseBlock}>
                            <div className={styles.caseTitle}>
                                <div className={styles.caseIco}>
                                    <img src={fileIco} alt="file" />
                                </div>
                                <p className={styles.caseTitleText}>
                                    Анализ систем
                                </p>
                            </div>
                            <p className={styles.caseDescText}>
                                Структурирование и упорядочивание документов систем, том числе промышленных.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(CasesBlock);