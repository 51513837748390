import React, { memo } from "react";
import styles from './ModalWindow.module.scss';
// import { RiCloseLine } from "react-icons/ri";

const ModalWindow = ({ setIsOpen }) => {
    return (
        <>
            <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
            <div className={styles.centered}>
                <div className={styles.modal}>
                    <div className={styles.modalWindowWrapper}>
                        <div className={styles.modalHeader}>
                            <div className={styles.HeaderTitleText}>
                            Заполнение заявки
                            </div>
                            <div className={styles.HeaderTitleDesc}>
                            Заполните данные и мы обязательно свяжемся с вами
                            </div>
                        </div>
                        <form className={styles.ModalForm}>
                            <div className={styles.ModalFormsInputsBlock}>
                                <input type="text" className={styles.inputField} placeholder="Как к вам можно обращаться" />
                                <input type="text" className={styles.inputField} placeholder="Адрес электронной почты" />
                                <input type="text" className={styles.inputField} placeholder="Телефон" />
                            </div>
                            <div className={styles.ModalFormsInputsBlock}>
                                <textarea className={styles.inputField} placeholder="Несколько слов о проекте" name="" id="" cols="30" rows="10">

                                </textarea>
                            </div>
                        </form>
                        <div className={styles.ModalForm}>
                            <div className={styles.ModalFormsInputsBlock}>
                                <button type="text" className={styles.ModalCloseInput} onClick={() => setIsOpen(false)}>Назад</button>
                            </div>
                            <div className={styles.ModalFormsInputsBlock}>
                                <button type="text" className={styles.ModalSendFill}>Отправить заявку</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(ModalWindow);