import React from "react";
import styles from './techStack.module.scss';
import android from '../../../resources/icons/android.svg';
import cpp from '../../../resources/icons/cpp.svg';
import csh from '../../../resources/icons/csh.svg';
import css from '../../../resources/icons/css3.svg';
import django from '../../../resources/icons/django.svg';
import flutter from '../../../resources/icons/flutter.svg';
import git from '../../../resources/icons/git.svg';
import go from '../../../resources/icons/go.svg';
import html5 from '../../../resources/icons/html5.svg';
import java from '../../../resources/icons/java.svg';
import kotlin from '../../../resources/icons/kotlin.svg';
import vstudio from '../../../resources/icons/vstudio.svg';
import ts from '../../../resources/icons/ts.svg';
import sass from '../../../resources/icons/sass.svg';
import python from '../../../resources/icons/python.svg';
import pgsql from '../../../resources/icons/postgresql.svg';
import php from '../../../resources/icons/php.svg';
import msql from '../../../resources/icons/mysql.svg';
import lines from '../../../resources/icons/lines.svg';


const TechStack = () => {
    return (
        <div className={styles.container}>
            <div className={styles.TechMain}>
                <div className={styles.techTitle}>
                    ТЕХНОЛОГИИ
                </div>
                <div className={styles.techBlock}>
                    <div className={styles.techTopStack}>
                        <img src={vstudio} alt="" className={styles.mobileInvis} />
                        <img src={android} alt="" />
                        <img src={cpp} alt="" />
                        <img src={django} alt="" />
                        <img src={css} alt="" />
                        <img src={csh} alt="" />
                        <img src={cpp} alt="" />
                        <img src={flutter} alt="" />
                        <img src={msql} alt="" />
                        <img src={ts} alt="" className={styles.mobileInvis}/>
                        <img src={kotlin} alt="" />
                    </div>
                    <div className={styles.techBotStack}>
                        <img src={java} alt="" />
                        <img src={go} alt="" />
                        <img src={lines} alt="" />
                        <img src={html5} alt="" />
                        <img src={git} alt="" />
                        <img src={php} alt="" />
                        <img src={python} alt="" />
                        <img src={sass} alt="" />
                        <img src={pgsql} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TechStack;