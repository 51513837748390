import React, { memo } from "react";
import styles from './MobileGame.module.scss';
import mobileGamesImg from '../../../resources/images/mobileGamesImg.png';
import rotatedGamepad from '../../../resources/images/rotatedGamepad.png';
import frontalGamepad from '../../../resources/images/frontalGamepad.png';

const MobileGame = () => {
    return (
        <div className={styles.container}>
            <div className={styles.MobileGameMain}>
                <div className={styles.MobileGTitleBlock}>
                    <div className={styles.MobileGTopTitle}>
                    MOBILE
                    </div>
                    <img src={rotatedGamepad} alt="mobileGames" className={styles.MobileGRotatedGamepadImg} />
                </div>
                <div className={styles.MobileGMiddleBlock}>
                    <div className={styles.MobileGMessage}>
                    let’s play
                    </div>
                    <img src={mobileGamesImg} alt="mobileGames" className={styles.MobileGMiddleImg} />
                </div>
                <div className={styles.MobileGBottomBlock}>
                    <img src={frontalGamepad} alt="GAMEPAD" className={styles.MobileGBottomGamepad} />
                    <div className={styles.MobileGBottonTitle}>
                    GAME
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(MobileGame);