import React, { memo } from "react";
import styles from './FillAppBlock.module.scss';
import fillAppImg from '../../../resources/images/fillAppImg.png';
import bbLeft from '../../../resources/images/backBubblesLeft.png';
import bbRight from '../../../resources/images/backBubblesRight.png';

const FillAppBlock = () => {
    return (
        <div className={styles.container}>
            <div className={styles.FAMain}>
                <div className={styles.bbBlock}>
                    <img src={bbLeft} className={styles.bbLeft} alt="bubbleL" />
                    <img src={bbRight} className={styles.bbRight} alt="bubbleR" />
                </div>
                <div className={styles.FillAppBlock}>
                    <img src={fillAppImg} alt="fillApp" />
                    <form>
                        <input className={styles.inputField} type="text" placeholder="Как к вам можно обращаться" />
                        <input className={styles.inputField} type="text" placeholder="Адрес электронной почты" />
                        <input className={styles.inputField} type="text" placeholder="Телефон" />
                        <textarea className={styles.inputField} name="" id="" cols="30" rows="4" placeholder="Несколько слов о проекте">

                        </textarea>
                        <button onClick={(e) => e.preventDefault()}>Заполнить заявку</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default memo(FillAppBlock);