import React, { memo, useState } from "react";
import styles from './BusinesSystems.module.scss';
// import classNames from "classnames";
import mackBook1 from '../../../resources/images/DeviceMacbookAir.png';
import mackbook2 from '../../../resources/images/DeviceMacbookAir2.png';
import mackBook3 from '../../../resources/images/gocoremackboock.png'
import BusinesBlockDescription from "../../../components/BusinesBlockDescription/BusinesBlockDescription";

const BusinesSystems = () => {

    let [erp, setErp] = useState(styles.businesBtn);
    let [crm, setCrm] = useState(styles.businesBtnCurrent);
    let [services, setServices] = useState(styles.businesBtn);
    let [mackbookImage, setMackbookImage] = useState(mackBook1);
    let [businesDesc, setBusinesDesc] = useState(<BusinesBlockDescription titleText="ARySEP"
                                                    descText="Система по оптимизации расходов и увеличения прибыли предприятия"
                                                    firstElText="Инновационная система управления персоналом"
                                                    secondElText="Позволяет в реальном времени отслеживать все рабочие процессы"
                                                    thirdElText="Анализ деятельности каждого сотрудника сразу по нескольким показателям"
                                                />);

    const setErpValue = () => {
        setErp(styles.businesBtnCurrent);
        setCrm(styles.businesBtn);
        setServices(styles.businesBtn);
        setMackbookImage(mackBook3);
        setBusinesDesc(<BusinesBlockDescription titleText="GOCOre"
                                    descText="Компания, которая выберет GoCore на старте разработки, или внедрит его в существующий IT департамент сэкономит:"
                                    firstElText="более 1,5 лет времени"
                                    secondElText="от 5 млн рублей"
                                    thirdElText="а также нервы руководства"
                        />);
    }

    const setCrmValue = () => {
        setErp(styles.businesBtn);
        setCrm(styles.businesBtnCurrent);
        setServices(styles.businesBtn);
        setMackbookImage(mackBook1);
        setBusinesDesc(<BusinesBlockDescription titleText="ARySEP"
                                    descText="Система по оптимизации расходов и увеличения прибыли предприятия"
                                    firstElText="Инновационная система управления персоналом"
                                    secondElText="Позволяет в реальном времени отслеживать все рабочие процессы"
                                    thirdElText="Анализ деятельности каждого сотрудника сразу по нескольким показателям"
                        />);
    }

    const setServicesValue = () => {
        setErp(styles.businesBtn);
        setCrm(styles.businesBtn);
        setServices(styles.businesBtnCurrent);
        setMackbookImage(mackbook2);
        setBusinesDesc(<BusinesBlockDescription titleText="Voda"
                                    descText="Платформа позволяющая организовывать митинги первой консультации с клиентами"
                                    firstElText="Сервис видеоконсультаций для сайта"
                                    secondElText="Позволяет проводить весь цикл сделки в режиме живого общения без использования сторонних сервисов"
                                    thirdElText="Возможность демонстрации экрана, видео с камеры, отправки документов, встроенный мессенджер"
                        />);
    }

    return (
        <div className={styles.container}>
            <div className={styles.BusinesMain}>
                <div className={styles.businesLeftBlock}>
                    <div className={styles.businesTitleBlock}>
                        <h1 className={styles.businesTitleText}>
                            Системы для бизнеса
                        </h1>
                        <p className={styles.businesTitleDesc}>
                            Создание ERP, CRM, аналитических систем и других, в том числе высоконагруженных и систем с использованием искусственного интеллекта и нейросетей.
                        </p>
                        <div className={styles.businesBtnsBlock}>
                            <div className={erp} onClick={setErpValue}>
                                <p className={styles.businesBtnText}>
                                    ERP
                                </p>
                            </div>
                            <div className={crm} onClick={setCrmValue}>
                                <p className={styles.businesBtnText}>
                                    CRM
                                </p>
                            </div>
                            <div className={services} onClick={setServicesValue}>
                                <p className={styles.businesBtnText}>
                                    Сервисы
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.busDescM}>
                        {businesDesc}
                    </div>
                </div>


                <div className={styles.businesMackBookBlock}>
                    <img src={mackbookImage} alt="mack-book" className={styles.businesMackBook} />
                </div>
            </div>
        </div>
    )
};

export default memo(BusinesSystems);