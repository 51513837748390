import React, { memo } from "react";
import styles from './GoCore.module.scss';
import gocoreImg from '../../../resources/images/GoCore.png';

const GoCore = () => {
    return (
        <div className={styles.container}>
            <div className={styles.GoMain}>
                <div className={styles.goTextBlock}>
                    <div className={styles.goTextTitle}>
                        Стратегическое управление проектом
                    </div>
                    <div className={styles.goTextSTitile}>
                        Платформа для разработки систем
                    </div>
                    <div className={styles.goTextDesc}>
                        Платформа для быстрой разработки ERP и SRM и других бизнес-систем.
                        <br />
                        <br />
                        Позволяет не «ломать» шаблонные системы, а создать свою с учетом особенностей бизнес-процессов
                    </div>
                </div>
                <div className={styles.goImgBlock}>
                    <img src={gocoreImg} alt="GoCore" />
                </div>
            </div>
        </div>
    )
};

export default memo(GoCore);