import React from "react";
import StarterBlock from "../Content/StarterBlock/StarterBlock";
import styles from './App.module.scss';
import CasesBlock from "../Content/CasesBlock/CasesBlock";
import BusinesSysytems from "../Content/BusinesSystems/BusinesSysytems";
import GoCore from "../Content/GoCore/GoCore";
import TechStack from "../Content/TechStack/TechStack";
import MobileApps from "../Content/MobileApps/MobileApps";
import TDTD from "../Content/2D3D/TDTD";
import WebApps from "../Content/WebApps/WebApps";
import GameDev from "../Content/GameDev/GameDev";
import MobileGame from "../Content/MobileGame/MobileGame";
import ItSchool from "../Content/ItSchool/ItSchool";
import FillAppBlock from "../Content/FillAppBlock/FillAppBlock";
import Footer from '../../components/Footer/Footer'

const App = () => {
    return (
        <div className={styles.AppContainer}>
            <StarterBlock/>
            <CasesBlock />
            <BusinesSysytems />
            <GoCore />
            <TechStack />
            <MobileApps />
            <TDTD />
            <WebApps />
            <GameDev />
            <MobileGame />
            <ItSchool />
            <FillAppBlock />
            <Footer />
        </div>
    )
};

export default App;