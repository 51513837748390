import React, { useState } from "react";
import styles from './2D3D.module.scss';
import motoImg from '../../../resources/images/3dmoto.png';
import characterImg from '../../../resources/images/3dcharacter.png';
import roomImg from '../../../resources/images/3droom.png';
import tdButtonImg from '../../../resources/images/3dbutton.png';
import inactiveBtnImg from '../../../resources/icons/arrinactive.svg';
import activeBtnImg from '../../../resources/icons/arractive.svg';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';

const TDTD = () => {
    const [swiper, setSwiper] = useState(null);
    const [prevArrowClass, setPrevArrowClass] = useState(styles.prevArrow);
    const [nextArrowClass, setNextArrowClass] = useState(styles.nextArrow);
    const [itemsBtnClass, setItemsBtnClass] = useState(styles.tdBtnActive);
    const [charactersClass, setCharactersClass] = useState(styles.tdBtn);
    const [designClass, setDesignClass] = useState(styles.tdBtn);

    const nextSlide = () => {
        swiper.slideNext();
    }

    const prevSlide = () => {
        swiper.slidePrev();
    }


    return (
        <div className={styles.container}>
            <div className={styles.TDMain}>
                <div className={styles.tdTitileBlock}>
                    <div className={styles.tdTitleTopBlock}>
                        <div className={styles.tdTitleText}>
                        3D и 2D
                        </div>
                        <div className={styles.tdTitleDescText}>
                        3D моделирование, 3D анимация, 2D анимация - все это в комплексе работ по созданию мультфильмов, 3D эффектов, сцен и локаций. 
                        </div>
                    </div>
                    <div className={styles.tdTitleBottomBlock}>
                    Работы под ключ, от сценария до запуска.
                </div>
                </div>
                    <div className={styles.slideWrapper}>
                    <Swiper spaceBetween={50}
                        slidesPerView={1}
                        loopedSlides={true}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => setSwiper(swiper)}
                        className={styles.FirstSwiper}>
                        <SwiperSlide className={styles.firstSwiperSlide}>
                            <img src={tdButtonImg} alt="button" className={styles.tdButtonImg} />
                            <img src={motoImg} alt="MOTO" className={styles.motoImg} />
                        </SwiperSlide>
                        <SwiperSlide className={styles.firstSwiperSlide}>
                            <img src={tdButtonImg} alt="button" className={styles.tdButtonImg} />
                            <img src={characterImg} alt="MOTO" className={styles.charImg} />
                        </SwiperSlide>
                        <SwiperSlide className={styles.firstSwiperSlide}>
                            <img src={tdButtonImg} alt="button" className={styles.tdButtonRoomImg} />
                            <img src={roomImg} alt="MOTO" className={styles.roomImg} />
                        </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className={styles.tdNavBlock}>
                        <div className={styles.arrowsBlock}>
                            <div onClick={prevSlide}>
                                <img src={activeBtnImg} alt="inactiveArrow" className={prevArrowClass} />
                            </div>
                            <div onClick={nextSlide}>
                                <img src={activeBtnImg} alt="activeArrow" className={nextArrowClass} />
                            </div>
                        </div>
                        
                        {/* <div className={styles.tdBtnsBlock}>
                            <div className={itemsBtnClass}>
                                Предметы
                            </div>
                            <div className={charactersClass}>
                                Персонажи
                            </div>
                            <div className={designClass}>
                                Дизайн интерьера
                            </div>
                        </div> */}
                    </div>
            </div>
        </div>
    )
};

export default TDTD;